<template>
  <v-card flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid v-if="o" class="pa-0">
      <v-row dense>
          <v-switch color="success" label="Active" readonly v-model="o.isActive" :loading="patching.includes('isActive')" class="shrink mr-4" @click.native="patch('isActive', !o.isActive)"></v-switch>
          <v-switch color="success" label="Public" readonly v-model="o.isPublic" :loading="patching.includes('isPublic')" class="shrink mr-4" @click.stop="patch('isPublic', !o.isPublic)"></v-switch>
          <v-switch color="success" label="Club" readonly v-model="o.isClub" :loading="patching.includes('isClub')" class="shrink mr-4" @click.stop="patch('isClub', !o.isClub)"></v-switch>
          <v-switch color="success" label="Tournament Promoter" readonly v-model="o.isTournamentPromoter" :loading="patching.includes('isTournamentPromoter')" class="shrink mr-4" @click.stop="patch('isTournamentPromoter', !o.isTournamentPromoter)"></v-switch>
          <v-switch color="success" label="Point System Only" readonly v-model="o.pointsOnly" :loading="patching.includes('pointsOnly')" class="shrink mr-4" @click.stop="patch('pointsOnly', !o.pointsOnly)"></v-switch>
          <v-switch color="success" label="Rating System Only" readonly v-model="o.ratingsOnly" :loading="patching.includes('ratingsOnly')" class="shrink mr-4" @click.stop="patch('ratingsOnly', !o.ratingsOnly)"></v-switch>
          <v-switch color="success" label="Deleted" readonly v-model="o.noShow" :loading="patching.includes('noShow')" class="shrink" @click.stop="patch('noShow', !o.noShow)"></v-switch>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Props"
            color="color3"
            v-model="o._Props"
            :disabled="!editing"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="text-end">
          <v-fab-transition>
            <v-btn
              v-if="editing"
              :key="`${dirty}`"
              fab
              small
              :disabled="!dirty"
              :loading="loading"
              color="success white--text"
              class="mr-2"
              @click.stop="save"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              :key="myFab.icon"
              fab
              small
              :color="myFab.color"
              @click.stop="myFab.click"
              :disabled="loading"
            >
              <v-icon>fas fa-{{myFab.icon}}</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['username'],
  data () {
    return {
      loading: true,
      organization: null,
      patching: [],
      editing: false,
      propsOg: null
    }
  },
  computed: {
    o () {
      return this.organization
    },
    myFab () {
      return this.editing ? {
        icon: 'times',
        color: 'error white--text',
        click: this.cancel
      } : {
        icon: 'pencil',
        color: 'color3 color3Text--text',
        click: this.edit
      }
    },
    dirty () {
      return this.o._Props !== this.propsOg
    }
  },
  methods: {
    edit () {
      this.propsOg = this.o._Props
      this.editing = true
    },
    cancel () {
      this.o._Props = this.propsOg
      this.editing = false
    },
    save () {
      this.patch('_Props', this.o._Props, () => {
        this.editing = false
      })
    },
    load () {
      this.loading = true
      this.$VBL.organization.vblAdmin.get(this.username)
        .then(r => {
          this.organization = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    patch (v, val, cb) {
      const dto = {
        id: this.o.id,
        username: this.o.username
      }
      dto[v] = val

      this.loading = true
      this.patching.push(v)
      this.$VBL.patch.organization(this.username, dto)
        .then(() => {
          this.organization[v] = val
          cb && cb()
        })
        .catch(e => {
          console.log(e.response)
        })
        .finally(() => {
          this.loading = false
          this.patching = this.patching.filter(f => f !== v)
        })
    }
  },
  watch: {
    username: function (n, o) {
      if (n && o && n !== o) this.load()
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style>

</style>
